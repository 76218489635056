import { DragDropModule } from "@angular/cdk/drag-drop";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { APP_ID, APP_INITIALIZER, ErrorHandler, enableProdMode, importProvidersFrom } from "@angular/core";
import { getApp, initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { getFirestore, provideFirestore } from "@angular/fire/firestore";
import { getFunctions, provideFunctions } from "@angular/fire/functions";
import { getStorage, provideStorage } from "@angular/fire/storage";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from "@angular/material-moment-adapter";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule } from "ngx-dropzone-wrapper";
import { AppRoutingModule } from "./app/app-routing.module";
import { BaseComponent } from "./app/base.component";
import { httpInterceptorProviders } from "./app/http-interceptors";
import { environment } from "./environments/environment";
import { commitHash } from "./environments/git-sha";
import { provideNgxStripe } from "ngx-stripe";

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: "https://agorify.com",
  maxFiles: 1,
  autoReset: null,
  autoProcessQueue: false,
};

Sentry.init({
  enabled: window?.location?.hostname === 'localhost' ? false : true,
  environment: environment.production ? 'production' : 'development',
  dsn: "https://4b5090c163274644abdf2820aba3c4c3@o1410804.ingest.sentry.io/6748346",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.25,
  release: commitHash,
});

if (environment.production) {
  enableProdMode();
}

const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

bootstrapApplication(BaseComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      FormsModule,
      // Needed because of auth-guard/service
      MatDialogModule,
      // Needed because it is used in a lot of places
      MatSnackBarModule,
      // Needed to support moment in material
      MatMomentDateModule,
      ReactiveFormsModule,
      AppRoutingModule,
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideStorage(() => getStorage()),
      provideStorage(() => getStorage(getApp(), environment.videoBucket)),
      provideFirestore(() => getFirestore()),
      provideAuth(() => getAuth()),
      provideFunctions(() => getFunctions()),
      DragDropModule,
      DropzoneModule
    ),
    { provide: APP_ID, useValue: 'eventapp-web' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
    httpInterceptorProviders,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxStripe(environment.stripeKey)
  ]
})
  .catch((err: any) => console.error(err));
