import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { combineLatest, first, map, of, switchMap, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { LoadingWrapperWithError } from './shared/loading-wrapper';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { environment } from 'src/environments/environment';

declare var gtag;

type Response =
  { type: 'success'; currency: string; amount: number; } |
  { type: 'error'; };

@Component({
  templateUrl: './payment-return.component.html',
  standalone: true,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    RouterLink,
    NgIf,
    AsyncPipe,
  ]
})
export class PaymentReturnComponent implements OnInit {

  data$: LoadingWrapperWithError<
    { type: 'success'; url: string } |
    { type: 'error'; url: string }
  >;

  constructor(
    private readonly authService: AuthService,
    private readonly http: HttpClient,
    private readonly route: ActivatedRoute,
  ) {

  }
  ngOnInit(): void {
    this.data$ = new LoadingWrapperWithError(
      combineLatest([
        this.route.queryParams,
        this.authService.currentOrg.pipe(first()),
      ])
        .pipe(
          switchMap(([queryParams, org]) =>
            queryParams.session_id
              ? this.http
                .post<Response>(`/api/orgs/${org.id}/checkout-session`, { sessionId: queryParams.session_id })
                .pipe(
                  switchMap((r) =>
                    // TODO: better way to refresh?
                    this.authService
                      .setCurrentOrganisation(org.id)
                      .pipe(
                        map(() => r)
                      )
                  ),
                  tap((r) => {
                    if (r.type === 'success' && environment.googleAdsConversion) {
                      gtag('event', 'conversion', {
                        'send_to': environment.googleAdsConversion,
                        'value': r.amount,
                        'currency': r.currency.toLocaleUpperCase(),
                      });
                    }
                  }),
                  map((r) => ({ ...r, url: queryParams.url ?? '/event/organisation' })),
                )
              : of({ type: 'error' as const, url: queryParams.url ?? '/event/organisation' })
          )
        )
    );
  }
}
