<div class="root-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="toolbar">
    <h1 class="app-name">
      <a routerLink="/">
        <img class="logo" [src]="'assets/images/agorify-logo-white.png'" />
      </a>
    </h1>
    <span class="spacer"></span>
    <app-shared-header-component></app-shared-header-component>
  </mat-toolbar>

  <div class="content-wrapper">
    <mat-card appearance="outlined">
      <mat-card-content>

        <div class="root-content-wrapper">
          <img src="assets/images/agorify-square-logo.svg" alt="Agorify" style="width: 29px; margin: auto;" />
          <h2>Explore Agorify</h2>
          <p style="text-align: center;">
            Discover everything Agorify has to offer. No matter if you are
            setting up a small internal seminar or a large scale exhibition with
            thousands of visitors, Agorify is your new secret weapon for
            providing a truly amazing digital experience.
          </p>
          <div class="card-wrapper spacing">
            <a [routerLink]="['/event']" class="card">
              <div class="img-container">
                <img src="assets/images/organizer.svg" alt="Organizer" style="height: 62px;" />
              </div>
              <div class="text-container">
                <h3>I'm an Organizer</h3>
                <p>
                  Create, manage and customise your event.
                </p>
              </div>
            </a>
            <a href="https://live.agorify.com/" class="card">
              <div class="img-container">
                <img src="assets/images/attendee.svg" alt="Organizer" style="height: 62px;" />
              </div>
              <div class="text-container">
                <h3>I'm an Attendee</h3>
                <p>
                  You are attending an event.
                </p>
              </div>
            </a>
          </div>
          <div style="text-align: center; padding-bottom: 32px;">
            <a mat-button color="primary" [routerLink]="['/exhibitor']">Exhibitor Center</a>
          </div>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
</div>
