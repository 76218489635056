import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth-guard.service';
import { canActivateCurrentOrganisation } from './event/organisation-guard.service';
import { InviteComponent } from './invite.component';
import { LoggedInGuard } from './logged-in-guard.service';
import { LoginComponent } from './login.component';
import { NoOrganisationComponent } from './no-organisation.component';
import { PageNotFoundComponent } from './not-found.component';
import { PaymentReturnComponent } from './payment-return.component';
import { RegisterComponent } from './register.component';
import { ResetPasswordComponent } from './reset-password.component';
import { RootComponent } from './root.component';
import { noActiveSubscriptionGuard } from './shared/no-active-subscription-guard.service';

const appRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: RootComponent
  },
  {
    path: '_backoffice',
    canActivate: [AuthGuard],
    loadChildren: () => import('./backoffice/backoffice-routing.module').then(m => m.routes),
  },
  { path: 'event', canActivate: [AuthGuard], loadChildren: () => import('./event/event-routing.module').then(m => m.routes) },
  { path: 'create', redirectTo: 'register' },
  { path: 'exhibitor', canActivate: [AuthGuard], loadChildren: () => import('./exhibitor/exhibitor-routing.module').then(m => m.routes), },
  { path: 'admin', canActivate: [AuthGuard], loadChildren: () => import('./admin/admin-routing.module').then(m => m.routes), },
  {
    path: 'purchase/:purchaseId',
    canActivate: [AuthGuard, canActivateCurrentOrganisation, noActiveSubscriptionGuard],
    loadComponent: () => import('./purchase/purchase.component').then(m => m.PurchaseComponent)
  },
  { path: 'organisation/:organisationId/onboarding-refresh', canActivate: [AuthGuard], component: NoOrganisationComponent },
  { path: 'no-organisation', canActivate: [AuthGuard], component: NoOrganisationComponent },
  { path: 'login', canActivate: [LoggedInGuard], component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'register', canActivate: [LoggedInGuard], component: RegisterComponent },
  { path: 'invite/:code', component: InviteComponent },
  { path: 'payment-return', component: PaymentReturnComponent },
  { path: 'not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
  constructor(_router: Router) { }
}
