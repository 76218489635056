<div class="login-container">
  <mat-card appearance="outlined" class="login-card">
    <img class="logo" src="assets/images/agorify-logo.png" alt="Agorify Logo" />
    <h2 class="title">Your event companion</h2>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form" autocomplete="off">
      <mat-form-field>
        <input id="email-input" matInput placeholder="Email" formControlName="email" autocomplete="off">
        <mat-error
          *ngIf="loginForm.controls['email'].hasError('email') && !loginForm.controls['email'].hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="loginForm.controls['email'].hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input id="password-input" matInput type="password" placeholder="Password" formControlName="password"
          autocomplete="off">
        <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <a [routerLink]="['/reset-password']" queryParamsHandling="preserve"
        class="link center grey underlined bottom-gap">
        Don’t remember your password?
      </a>
      <button class="login-button" type="submit" mat-raised-button color="primary"
        [disabled]="loading || !loginForm.valid">
        Login
      </button>
      <mat-progress-bar *ngIf="loading" color="primary" mode="indeterminate"></mat-progress-bar>
      <a id="create-link" [routerLink]="['/register']" queryParamsHandling="preserve" class="link center top-gap">
        <strong>Get started for free! </strong>
        <span class="blue" class="blue">Create account.</span>
      </a>
    </form>
  </mat-card>
</div>
