import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';

@Component({
    template: `
      <div class="not-found-container">
        <mat-card appearance="outlined" class="not-found-card">
          <img class="logo" [src]="'assets/images/agorify-logo.png'" />
          <h2 class="center">Page not found</h2>
          <a [routerLink]="['/']" class="link center grey underlined">Go back to homepage</a>
        </mat-card>
      </div>
    `,
    styleUrls: ['./not-found.component.css'],
    standalone: true,
    imports: [MatCardModule, RouterLink]
})
export class PageNotFoundComponent {}
